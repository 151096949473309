import Vue from 'vue'
import App from './App.vue'
import router from './router'
import {Tabs, TabPane, Col, Row, Button, Select,Submenu, Menu,MenuItem, Drawer, Dialog} from 'element-ui'
import WebTracing from '@web-tracing/vue2'
import { BLink, BButton, BContainer, BRow, BCol} from 'bootstrap-vue'
// import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/css/bootstrap-grid.min.css'
import 'bootstrap/dist/css/bootstrap-reboot.min.css'
import '@/style/scss/style.scss';

import {isMobile,isMinMobile, debounce, dispatchEventStroage} from '@/utils/utils.js'
Vue.use(dispatchEventStroage)

console.log('env', process.env.NODE_ENV)
const developEnv = process.env.NODE_ENV === 'development'
const dsn = developEnv ? 'http://192.168.99.10:32013/ali/log/save' : '/ali/log/save'

Vue.use(WebTracing, {
  dsn: dsn,
  appCode: 'primihub-website',
  appName: 'Primihub官网',
  debug: !!developEnv,
  pv: true,
  performance: true,
  error: true,
  event: true,
  recordScreen: false, // 是否开启录屏功能
  cacheMaxLength: 10,
  cacheWatingTime: 5000,
  scopeError: true
})

Vue.prototype.$isMobile = isMobile();
Vue.prototype.$isMinMobile = isMinMobile();
window.localStorage.setItem('isMobile', isMobile())
window.localStorage.setItem('isMinMobile', isMinMobile())
window.addEventListener('resize',debounce(function(e){
  Vue.prototype.$isMobile = isMobile();
  Vue.prototype.$isMinMobile = isMinMobile();
  window.localStorage.setItem('isMobile', isMobile())
  window.localStorage.setItem('isMinMobile', isMinMobile())
},100))

import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload)
// "vue-video-player": "^6.0.0"
// import VideoPlayer from "vue-video-player";
// import "vue-video-player/node_modules/video.js/dist/video-js.css";


// Vue.use(NavbarPlugin, ModalPlugin, AlertPlugin,ButtonPlugin)
Vue.use(Tabs)
Vue.use(TabPane)
// Vue.use(Form)
// Vue.use(FormItem)
// Vue.use(Input)
Vue.use(Col)
Vue.use(Row)
Vue.use(Button)
Vue.use(Select)
Vue.use(Submenu)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Drawer)
Vue.use(Dialog)

Vue.component('BContainer', BContainer)
Vue.component('BRow', BRow)
Vue.component('BCol', BCol)
Vue.component('BButton', BButton)
Vue.component('BLink', BLink)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
