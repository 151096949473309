import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location){
return originalPush.call(this , location).catch(err=>err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location){
return originalReplace.call(this , location).catch(err=>err)
}
export default new VueRouter({
    routes:[
        {
            path:'/',
            component: () => import('@/views/index'),
            meta:{
                title:"index",
                keepAlive: true,
            },
            redirect:{
                name:'Home'
            },
            children:[
                {
                    path: 'home',
                    name: 'Home',
                    component: () => import('@/views/homePage'),
                    meta:{
                        title:"首页",
                        keepAlive: true,
                    }
                },
                {
                    path: 'solution/:type?',
                    name: 'Solution',
                    component: () => import('@/views/Solution/index'),
                    // props: route => ({ type: route.params.type || 'jinrong' }),
                    meta:{
                        title:"解决方案",
                        keepAlive: true,
                    }
                },
                {
                    path: 'core',
                    name: 'Core',
                    component: () => import('@/views/Core'),
                    meta:{
                        title:"核心技术",
                        keepAlive: true,
                    }
                },
                {
                    path: 'product',
                    name: 'Product',
                    component: () => import('@/views/Product'),
                    meta:{
                        title:"产品介绍",
                        keepAlive: true,
                    }
                },
                {
                    path: 'aboutUs/:id?',
                    name: 'AboutUs',
                    component: () => import('@/views/aboutUs'),
                    meta:{
                        title:"关于我们",
                        keepAlive: true,
                    }
                },
                {
                    path: '/apply',
                    name: 'Apply',
                    component: () => import('@/views/ApplyPage')
                },
            ]
        },
        // {
        //     path: '/apply',
        //     name: 'Apply',
        //     component: () => import('@/views/ApplyPage')
        // },
        {
            path:"/contactUs",
            name:"contactUs",
            component: () => import('@/views/contactUs')
        },
       
        // 404 page must be placed at the end !!!
        { path: '*', redirect: '/', hidden: true }
    ],
    scrollBehavior: () => ({ y: 0 }),
});
