function isMobile() {
  let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  const clientWidth = document.body.scrollWidth;
  if(clientWidth < 1200){
      isMobile = true;
  }
  return isMobile
}
function isMinMobile() {
  let isMinMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  const clientWidth = document.body.scrollWidth;
  if(clientWidth < 750){
    isMinMobile = true;
  }
  return isMinMobile;
}

function dispatchEventStroage() {
const signSetItem = localStorage.setItem
localStorage.setItem = function (key, val) {
  let setEvent = new Event('setItemEvent')
  setEvent.key = key
  setEvent.newValue = val
  window.dispatchEvent(setEvent)
  // eslint-disable-next-line prefer-rest-params
  signSetItem.apply(this, arguments)
}
}

// 防抖
/** 
* @param function
* @param {number} [delay=500] 
*/
function debounce(fn,delay = 500){
let timer=null;
return function(){
  let context=this;
  let args=arguments;
  clearTimeout(timer);
  timer=setTimeout(function(){
    fn.apply(context,args);
  },delay);
}
}
// 节流
function throttle(fun, delay = 1000) {
  let last, deferTimer
  return function (args) {
      let that = this
      let _args = args
      let now = +new Date()
      if (last && now < last + delay) {
          clearTimeout(deferTimer)
          deferTimer = setTimeout(function () {
              last = now
              fun.apply(that, _args)
          }, delay)
      }else {
          last = now
          fun.apply(that,_args)
      }
  }
}
// 监听字体大小变化
function resizeFont() {
  let winW = document.documentElement.clientWidth > 750 ? 750 : document.documentElement.clientWidth
  document.documentElement.style.fontSize = (winW / 750) * 100 + 'px'
}
export {
  isMobile,
  isMinMobile,
  debounce,
  throttle,
  dispatchEventStroage,
  resizeFont
}